<template>
    <div style="margin-top:20px;">
        <v-data-table
            class="table"
            :items="items"
            :loading="loader"
            hide-default-footer
            style="border-bottom:1px solid black;margin-bottom:30px;"
            :headers="ref_headers"
        >
          <template v-slot:item.team="{item}">
            <div class="text-center project-container">
                {{item.team.name}} <br>
                {{item.team.phone}}
            </div>
          </template>
          <template v-slot:item.date_joined="{item}">
              <div class="text-center project-container">
                  <p> {{ new Date(item.created).toJSON().slice(0,10).replace(/-/g,'/')  }} </p>
                  <p> </p>
              </div>
          </template>
          <template v-slot:item.referral="{item}">
              <div class="text-center project-container">
                  <p v-html="item.referral">  </p>
                  <p> </p>
              </div>
          </template>
        </v-data-table>
         <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
          @input="next"
        >
        </v-pagination>
    </div>
</template>

<script>

import api from "@/helper-axios.js";

export default {
 name: 'TeamCommissionTable',
 data(){
     return {
        items:[],
        loader:false,
        page:1,
        pageCount:1,
        ref_headers:[ 
				  { text: 'DATE',align: 'center',value: 'date_joined',class : 'theader', sortable:false},
          { text: 'TEAMMATE DETAILS',align: 'center',value: 'team',class : 'theader', sortable:false},
          { text: 'REFERRALS DETAILS',align: 'center',value: 'referral',class : 'theader', sortable:false},
          { text: 'PRODUCT',align: 'center',value: 'plan_type', class : 'theader', sortable:false},
          { text: 'TYPE',align: 'center',value: 'commission_type',class : 'theader', sortable:false},
          { text: 'TRANSACTION AMOUNT',align: 'center',value: 'paid_amount',class : 'theader', sortable:false},
          { text: 'COMMISSION %',align: 'center',value: 'commission_percent',class : 'theader', sortable:false},
          { text: 'COMMISSION AMOUNT',align: 'center',value: 'commission_amount',class : 'theader', sortable:false},
        ]
     }
 },
 methods:{
   next(){
     this.getCommission()
   },

   getCommission(){
     const uid = localStorage.getItem('uid')
     api.get(`${this.$store.state.Auth.staging_url}/api/team/commission_list/${uid}/${this.page}/`)
      .then((res)=>{
        console.log(res.data);
        this.items = res.data.results;
        this.num = res.data.num;
      })
      .catch((err)=>{
        console.log(err);
      })
   },
 },
 mounted(){
   this.getCommission()
 }
}
</script> 

<style scoped>
  	.theader{
		font-size:18px;
	  height:70px;
	  text-emphasis: wrap;
	  border-top:None !important;
	  background-color: #F5F6FA;
	}
	.project-container p {
    font-size: 12px;
    font-weight:450;
    margin: 0;
    padding:0;

  }

</style>